<script setup lang="ts">
import { match } from "ts-pattern";
import OnboardingStickyButtonWrapper from "~/components/onboarding/OnboardingStickyButtonWrapper.vue";
import { useConditions } from "~/composables/useConditions";
import type { StaticScreen } from "~/types/onboarding/onboardingScreen";
import type { RegistrationParams } from "~/types/onboarding/registrationParams";
import { getOnboardingConditions } from "~/utils/onboardingConditions";
import { useOnboardingStore } from "~/stores/useOnboardingStore";
import { mapPersonalizedMotivationToFirstBenefit } from "~/resources/onboarding/mappers/benefits";

const { hasSkippedFunnel } = useConditions();

interface Props {
  screen: StaticScreen;
  registrationParams: RegistrationParams;
}

const props = defineProps<Props>();

const { getOrGetConditionalValue } = getOnboardingConditions(props);
const { $pinia } = useNuxtApp();
const { singleChoiceSelectedOptions } = storeToRefs(useOnboardingStore($pinia));

interface Emits {
  (
    e: "next",
    value: {
      nextScreenId: string;
    },
  ): void;
}

const emit = defineEmits<Emits>();

const isLoading = ref(false);

function next() {
  emit("next", {
    nextScreenId: getOrGetConditionalValue(props.screen.nextStep),
  });
  isLoading.value = true;
}

const benefits = computed(() => {
  let secondKey = match(props.registrationParams.goal)
    .with("gain", () => "app.profunnel.benefit_screen.benefit.healthy_controlled_weight_gain")
    .with("maintain", () => "app.profunnel.benefit_screen.benefit.maintaining_weight_effortlessly")
    .with("build_muscle", () => "app.profunnel.benefit_screen.benefit.gaining_muscle_fitness_goals")
    .with("lose", () => "app.profunnel.benefit_screen.benefit.healthy_sustainable_weight_loss")
    .otherwise(() => "app.profunnel.benefit_screen.benefit.reaching_health_fitness_goals");

  if (hasSkippedFunnel.value) {
    secondKey = "app.profunnel.benefit_screen.benefit.reaching_health_fitness_goals";
  }

  let firstKey = "app.profunnel.benefit_screen.benefit.increased_confidence_well_being";
  const firstKeyPersonalized = mapPersonalizedMotivationToFirstBenefit(
    singleChoiceSelectedOptions.value["screenid:onboarding.encouraging_flow.personal.personalized_question"],
  );

  if (firstKeyPersonalized && useFeatureFlags().personalized_benefits_screen.isOn().value) {
    firstKey = firstKeyPersonalized;
  }

  return [
    {
      color: "blue",
      titleTranslationKey: firstKey,
      iconUrl: "https://assets.yazio.com/frontend/images/web-funnels/icons/hands-heart.svg",
    },
    {
      color: "green",
      titleTranslationKey: secondKey,
      iconUrl: "https://assets.yazio.com/frontend/images/web-funnels/icons/scales.svg",
    },
    {
      color: "orange",
      titleTranslationKey: "app.profunnel.benefit_screen.benefit.higher_success_chance",
      iconUrl: "https://assets.yazio.com/frontend/images/web-funnels/icons/time-span.svg",
    },
  ];
});

const showButton = ref(false);

onMounted(() => setTimeout(() => (showButton.value = true), benefits.value.length * 200 + 100));
</script>

<template>
  <OnboardingStickyButtonWrapper
    :button-text="$t('registration.general.next')"
    :show-button="showButton"
    :is-loading="isLoading"
    @button-click="next"
  >
    <div class="container flex flex-col gap-10 px-bs-container">
      <div class="relative inline-block w-fit self-center">
        <h1>{{ $t("app.profunnel.benefit_screen.heading.what_you_can_look_forward_to") }}</h1>
        <div class="flex flex-col gap-4">
          <div
            v-for="(benefit, index) in benefits"
            :key="benefit.titleTranslationKey"
            class="flex animate-fade gap-4 rounded-xl border-2 p-4"
            :class="{
              'border-delight-blue-300 bg-delight-blue-50': benefit.color === 'blue',
              'border-delight-green-300 bg-delight-green-50': benefit.color === 'green',
              'border-delight-orange-300 bg-delight-orange-50': benefit.color === 'orange',
            }"
            :style="`animation-delay: calc(${index} * 0.2s)`"
          >
            <img :src="benefit.iconUrl" alt="" class="size-10" />
            <div class="flex items-center">
              <span v-html="$t(benefit.titleTranslationKey)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </OnboardingStickyButtonWrapper>
</template>
