<script lang="ts" setup>
import OnboardingBtn from "~/components/onboarding/OnboardingBtn.vue";
import OnboardingDiscountBanner from "~/components/onboarding/OnboardingDiscountBanner.vue";
import OnboardingSkipButton from "~/components/onboarding/OnboardingSkipButton.vue";
import OnboardingStylingChanges from "~/components/onboarding/utils/OnboardingStylingChanges.vue";
import type { BaseScreen } from "~/types/onboarding/onboardingScreen";
import type { RegistrationParams } from "~/types/onboarding/registrationParams";
import { useFunnelStore } from "~/stores/useFunnelStore";
import OnboardingFoodBanner from "~/components/onboarding/OnboardingFoodBanner.vue";
import { getOnboardingConditions } from "~/utils/onboardingConditions";
import { useStringFeatureFlags } from "~/composables/useFeatureFlags";

type Props = {
  screen: BaseScreen;
  registrationParams: RegistrationParams;
  showDiscountBanner?: boolean;
  showSkipButtons?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  showDiscountBanner: false,
  showSkipButtons: true,
  screen: undefined,
  registrationParams: undefined,
});

const { getOrGetConditionalValue } = getOnboardingConditions(props);
const isAlternativeFunnel = useFunnelStore().isAlternativeVariant();
const skipButtonActive = props.showSkipButtons && !isAlternativeFunnel;
const showHeader = isAlternativeFunnel;
const { singleChoiceSelectedOptions } = storeToRefs(useOnboardingStore());

interface Emits {
  (
    e: "next",
    value: {
      params: Partial<RegistrationParams>;
      nextScreenId: string;
    },
  ): void;
  (e: "skip"): void;
}

const emit = defineEmits<Emits>();

function selectOption(range: string) {
  singleChoiceSelectedOptions.value["age-range"] = range;
  emit("next", {
    params: {},
    nextScreenId: getOrGetConditionalValue(props.screen.nextStep),
  });
}

const { animated_first_page_illustrations } = useStringFeatureFlags();

const illustrationFlag = computed(() => animated_first_page_illustrations.getStringValue().value);
const illustrations: Record<(typeof animated_first_page_illustrations.options)[number], Array<string>> = {
  "OFF": [
    "https://images.yazio.com/frontend/web-funnels/onboarding/illustrations/age-group-1.png",
    "https://images.yazio.com/frontend/web-funnels/onboarding/illustrations/age-group-2.png",
    "https://images.yazio.com/frontend/web-funnels/onboarding/illustrations/age-group-3.png",
    "https://images.yazio.com/frontend/web-funnels/onboarding/illustrations/age-group-4.png",
  ],
  "STATIC": [
    "https://images.yazio.com/frontend/web-funnels/onboarding/illustrations/age-group-1-apple.png",
    "https://images.yazio.com/frontend/web-funnels/onboarding/illustrations/age-group-2-carrot.png",
    "https://images.yazio.com/frontend/web-funnels/onboarding/illustrations/age-group-3-radish.png",
    "https://images.yazio.com/frontend/web-funnels/onboarding/illustrations/age-group-4-broccoli.png",
  ],
  "ANIMATED": [
    "https://assets.yazio.com/frontend/images/web-funnels/animation/age-group-1-apple.webp",
    "https://assets.yazio.com/frontend/images/web-funnels/animation/age-group-2-carrot.webp",
    "https://assets.yazio.com/frontend/images/web-funnels/animation/age-group-3-radish.webp",
    "https://assets.yazio.com/frontend/images/web-funnels/animation/age-group-4-broccoli.webp",
  ],
};

const animationBackgroundColors = ["bg-[#FBDDE3]", "bg-[#FCEEE3]", "bg-[#EFDCFC]", "bg-[#E9F9E6]"];
const staticBackgroundColors = [
  "bg-delight-red-50",
  "bg-delight-orange-50",
  "bg-delight-purple-50",
  "bg-delight-green-50",
];
</script>

<template>
  <div>
    <OnboardingStylingChanges
      use-transparent-background
      hide-progress
      :hide-header="!showHeader"
    ></OnboardingStylingChanges>
    <OnboardingSkipButton v-if="skipButtonActive" :style="'close'" @skip="$emit('skip')"></OnboardingSkipButton>

    <div class="mt-8 flex flex-col items-center gap-8">
      <img
        v-if="!showHeader"
        src="https://assets.yazio.com/frontend/images/web-funnels/layout/yazio-logo-rounded.svg"
        alt="YAZIO Logo"
        height="36"
        class="h-9"
      />
      <div v-else class="mt-12"></div>

      <OnboardingDiscountBanner v-if="showDiscountBanner" />
      <OnboardingFoodBanner />

      <div class="flex flex-col items-center gap-3">
        <h1 class="m-0 mb-1.5 text-center text-white">
          {{ $t("text.webfunnel.age_group") }}
        </h1>

        <div class="flex flex-wrap justify-center gap-4">
          <OnboardingBtn
            is-column
            translation-key="text.webfunnel.age_group_1"
            :image="illustrations[illustrationFlag][0]"
            :full-width="false"
            :background-color="
              illustrationFlag === 'STATIC'
                ? staticBackgroundColors[0]
                : illustrationFlag === 'ANIMATED'
                  ? animationBackgroundColors[0]
                  : undefined
            "
            @click="selectOption('16-29')"
          />

          <OnboardingBtn
            is-column
            translation-key="text.webfunnel.age_group_2"
            :image="illustrations[illustrationFlag][1]"
            :full-width="false"
            :background-color="
              illustrationFlag === 'STATIC'
                ? staticBackgroundColors[1]
                : illustrationFlag === 'ANIMATED'
                  ? animationBackgroundColors[1]
                  : undefined
            "
            @click="selectOption('30-49')"
          />

          <OnboardingBtn
            is-column
            translation-key="text.webfunnel.age_group_3"
            :image="illustrations[illustrationFlag][2]"
            :full-width="false"
            :background-color="
              illustrationFlag === 'STATIC'
                ? staticBackgroundColors[2]
                : illustrationFlag === 'ANIMATED'
                  ? animationBackgroundColors[2]
                  : undefined
            "
            @click="selectOption('50-69')"
          />

          <OnboardingBtn
            is-column
            translation-key="text.webfunnel.age_group_4"
            :image="illustrations[illustrationFlag][3]"
            :full-width="false"
            :background-color="
              illustrationFlag === 'STATIC'
                ? staticBackgroundColors[3]
                : illustrationFlag === 'ANIMATED'
                  ? animationBackgroundColors[3]
                  : undefined
            "
            @click="selectOption('70+')"
          />
        </div>
      </div>

      <OnboardingSkipButton v-if="skipButtonActive" @skip="$emit('skip')"></OnboardingSkipButton>
    </div>
  </div>
</template>
